import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
			light: {
				primary: "#E41270",
				secondary: "#0983BC",
			},
		},
  },
  lang: {
		locales: { es },
		current: "es",
	},

});

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    propuesta : localStorage.getItem('propuesta') ? JSON.parse( localStorage.getItem('propuesta') ) : null
  },
  getters:{
    getPropuesta: (state) => state.propuesta
  },
  mutations: {
    savePropuesta(state, propuesta) {
      state.propuesta = propuesta
      localStorage.setItem('propuesta', JSON.stringify(propuesta))
    }
  },
  actions: {
  },
  modules: {
  }
})

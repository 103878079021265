import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

import VueMeta from "vue-meta";
Vue.use(VueMeta);

window.axios = axios.create()

Vue.use(require('vue-cookies'))

Vue.config.productionTip = false

// if (top != self)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
